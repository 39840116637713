import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';

import DebouncableInput from 'web-app/react/components/form-elements/debouncable-input';
import PlatformHelper from 'web-app/helpers/platform-helper';
import ReactOnMeta from 'web-app/react/components/form-elements/containers/react-on-meta';
import {s2, s0, getSpacing} from 'web-app/styleguide/spacing';

export const DecorationPlacement = {
    LEFT: 'LEFT',
    RIGHT: 'RIGHT',
};

export const Decoration = styled.div`
    padding: 0 ${getSpacing(s2)};
    font-weight: 600;
    font-size: ${props => props.theme.fontConfiguration.sizes.Caption};
    display: inline-block;
`;

const InputContainer = styled.div`
    flex-grow: 1;
    display: inline-flex;
`;

export const Border = styled.div`
    border: 1px solid ${props => props.theme.borderConfiguration.defaultColor};
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    border-radius: 4px;
    transition: box-shadow 0.5s, border-color 0.15s ease-out;
`;

// eslint-disable-next-line no-unused-vars
export const Input = styled(({decorationPlacement, textAlign, cornerStyle, ...rest}) => <DebouncableInput {...rest} />)`
    & {
        flex-grow: 1;
        width: 1%;
        border: 0;
        display: inline-block;
        margin: 0;
        font-size: ${props => props.theme.fontConfiguration.sizes.Caption};
        background: transparent;
        height: 36px;
        box-sizing: border-box;

        ${props =>
            props.decorationPlacement === DecorationPlacement.RIGHT
                ? css`
                      padding: ${getSpacing([s2, s0, s2, s2])};
                  `
                : css`
                      padding: ${getSpacing([s2, s2, s2, s0])};
                  `}

        &:focus, &:active {
            border: 0;
            outline: none;
            & + ${Border} {
                border-color: ${props => props.theme.borderConfiguration.focus};
            }
        }

        &:disabled {
            background: transparent;
            color: inherit;

            & + ${Border} {
                border-color: transparent;
            }
        }

        ${props =>
            props.textAlign
                ? css`
                      text-align: ${props => props.textAlign};
                  `
                : ''}
    }
`;

const Container = styled.div`
    position: relative;
    display: flex;
    align-items: ${() => (PlatformHelper.isIos() ? 'center' : 'baseline')};
    width: 100%;
    border-radius: 4px;
    background: ${props => props.theme.invertedText};

    &:hover {
        input:not(:focus) + ${Border} {
            border-color: ${props => props.theme.borderConfiguration.hover};
        }
    }

    ${props =>
        props.disabled
            ? css`
                  background-color: ${props => props.theme.borderConfiguration.disabled};
                  color: ${props => props.theme.textDisabled};
              `
            : ''}

    ${props =>
        props.ispassive
            ? css`
                  color: ${props => props.theme.text};
                  background: transparent;

                  ${Border} {
                      display: none;
                  }

                  ${Input} {
                      border-color: transparent;
                      background: transparent;
                      color: ${props => props.theme.text};
                      -webkit-text-fill-color: ${props => props.theme.text};
                      opacity: 1;
                      padding-left: 0; // Only doing this because that's how our other components behave...
                      text-align: center;
                  }

                  ${InputContainer} {
                      flex-grow: 0;
                  }
              `
            : ''}
`;

export const DecoratedInput = props => {
    const {
        decoration,
        input,
        inputClassName,
        className,
        disabled,
        ispassive,
        textAlign,
        decorationPlacement,
        value,
        ...rest
    } = props;

    const valueToUse = input ? input.value : value;

    const decorationComponent = <Decoration>{decoration}</Decoration>;
    return (
        <ReactOnMeta {...props}>
            <Container
                className={className}
                disabled={disabled}
                ispassive={ispassive}
                decorationPlacement={decorationPlacement}
            >
                {decorationPlacement === DecorationPlacement.LEFT ? decorationComponent : null}
                <InputContainer>
                    <Input
                        className={inputClassName}
                        decorationPlacement={decorationPlacement}
                        disabled={disabled}
                        textAlign={textAlign}
                        size={
                            ispassive === 'true' && (valueToUse !== undefined || valueToUse !== null)
                                ? String(valueToUse).length
                                : undefined
                        }
                        {...rest}
                        {...input}
                        value={valueToUse}
                    />
                    <Border />
                </InputContainer>
                {decorationPlacement === DecorationPlacement.RIGHT ? decorationComponent : null}
            </Container>
        </ReactOnMeta>
    );
};

DecoratedInput.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    decoration: PropTypes.node,
    input: PropTypes.object,
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    disabled: PropTypes.bool,
    ispassive: PropTypes.string,
    textAlign: PropTypes.string,
    debounced: PropTypes.bool,
    decorationPlacement: PropTypes.oneOf(Object.values(DecorationPlacement)),
};

DecoratedInput.defaultProps = {
    decorationPlacement: DecorationPlacement.LEFT,
};

export default DecoratedInput;
