import React from 'react';
import styled, {css} from 'styled-components';

import {useEventHandler} from 'web-app/react/hooks/use-event-handler';
import {hasValue, isValueInEnum} from 'web-app/util/typescript';
import ProgressPills, {PillAppearance, PillState} from 'web-app/react/components/progress-pills/progress-pills';
import {addHover, media} from 'web-app/styleguide/utils';
import Backspace from 'web-app/react/components/icons/legacy/lazy-icons/backspace';
import PlatformHelper from 'web-app/helpers/platform-helper';
import {getSpacing, s8} from 'web-app/styleguide/spacing';
import {Base} from 'web-app/react/components/layout/layout';

// eslint-disable-next-line no-restricted-syntax
export enum PinAppearance {
    Colorful = 'Colorful',
    Simple = 'Simple',
}

// eslint-disable-next-line no-restricted-syntax
export enum PinLoadingState {
    Success = 'Success',
    Error = 'Error',
    Loading = 'Loading',
}

interface ContextualButton {
    icon: React.ReactNode;
    onClick: () => void;
}
interface BaseProps {
    onChange: (pin?: string) => void;
    onFinish: (pin: string) => void;
    pinSize: number;
    value?: string;
    contextualButton?: ContextualButton;
    className?: string;
}

type InputProps = BaseProps & ({withPills: true; pinState: PinLoadingState | undefined} | {withPills: false});

// eslint-disable-next-line no-restricted-syntax
enum KeyCode {
    Zero = '0',
    One = '1',
    Two = '2',
    Three = '3',
    Four = '4',
    Five = '5',
    Six = '6',
    Seven = '7',
    Eight = '8',
    Nine = '9',
    Backspace = 'Backspace',
    __CONTEXTUAL = '__CONTEXTUAL',
    __EMPTY = '__EMPTY',
}

const KeyCodeValues = [
    KeyCode.Zero,
    KeyCode.One,
    KeyCode.Two,
    KeyCode.Three,
    KeyCode.Four,
    KeyCode.Five,
    KeyCode.Six,
    KeyCode.Seven,
    KeyCode.Eight,
    KeyCode.Nine,
    KeyCode.Backspace,
    KeyCode.__CONTEXTUAL,
    KeyCode.__EMPTY,
];

/**
 * This hook is used to create a ref that always updates to the latest value.
 * I'm using this to get access to the value inside a memoised callback without
 * creating a new function every time it changes.
 */
function useSyncedRef<T>(value: T) {
    const ref = React.useRef(value);
    ref.current = value;
    return ref;
}

const makeValue = (prev: string | undefined, action: KeyCode) => {
    switch (action) {
        case KeyCode.Backspace:
            if (!hasValue(prev) || prev.length === 0) {
                return prev;
            }
            return prev.slice(0, -1);
        case KeyCode.__CONTEXTUAL:
        case KeyCode.__EMPTY:
            return prev;
        default:
            return `${hasValue(prev) ? prev : ''}${action}`;
    }
};

export const PIN: React.FC<React.PropsWithChildren<InputProps>> = ({
    onChange,
    pinSize,
    value,
    onFinish,
    contextualButton,
    className,
    ...rest
}) => {
    const valueRef = useSyncedRef(value);

    const handleChange = React.useCallback(
        (newValue: string | undefined) => {
            onChange(newValue);
            if (hasValue(newValue) && newValue.length === pinSize) {
                onFinish(newValue);
            }
        },
        [onChange, onFinish, pinSize],
    );

    useEventHandler({
        target: React.useRef(document),
        eventName: 'keydown',
        debounce: 0,
        handler: React.useCallback(
            (e: KeyboardEvent) => {
                if (e.metaKey || e.shiftKey || e.ctrlKey || e.altKey) {
                    return;
                }
                const keyCode = e.key as KeyCode;
                if (!KeyCodeValues.includes(keyCode)) {
                    return;
                }
                handleChange(makeValue(valueRef.current, keyCode));
            },
            [valueRef, handleChange],
        ),
    });

    const handleClick = React.useCallback(
        (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
            if (!('getAttribute' in e.target)) {
                return;
            }
            const target = e.target as HTMLDivElement;
            const targetValue = target.getAttribute('data-pin-value');
            if (!hasValue(targetValue) || !isValueInEnum(KeyCode, targetValue)) {
                return;
            }
            if (targetValue === KeyCode.__CONTEXTUAL && hasValue(contextualButton)) {
                contextualButton.onClick();
            } else {
                handleChange(makeValue(valueRef.current, targetValue));
            }
        },
        [contextualButton, handleChange, valueRef],
    );

    const isTouch = PlatformHelper.isTouch();
    const handlers = {
        onClick: isTouch ? undefined : handleClick,
        onTouchStart: isTouch ? handleClick : undefined,
    };

    return (
        <div {...handlers} className={className}>
            {rest.withPills ? (
                <Base padding="0 10%">
                    <ProgressPills
                        appearance={PillAppearance.Simple}
                        count={pinSize}
                        activeCount={value ? value.length : 0}
                        pillState={getPillState(rest.pinState)}
                    />
                </Base>
            ) : null}
            <Static contextualButton={contextualButton} />
        </div>
    );
};

export const getPillState = (pinState?: PinLoadingState) => {
    switch (pinState) {
        case PinLoadingState.Loading:
            return PillState.Loading;
        case PinLoadingState.Error:
            return PillState.Error;
        case PinLoadingState.Success:
        default:
            return PillState.Success;
    }
};

const Static = React.memo<{contextualButton?: ContextualButton}>(({contextualButton}) => {
    return (
        <PINButtonContainer>
            <PINButton display="1" value={KeyCode.One} />
            <PINButton display="2" value={KeyCode.Two} />
            <PINButton display="3" value={KeyCode.Three} />
            <PINButton display="4" value={KeyCode.Four} />
            <PINButton display="5" value={KeyCode.Five} />
            <PINButton display="6" value={KeyCode.Six} />
            <PINButton display="7" value={KeyCode.Seven} />
            <PINButton display="8" value={KeyCode.Eight} />
            <PINButton display="9" value={KeyCode.Nine} />
            {hasValue(contextualButton) ? (
                <PINButton display={contextualButton.icon} value={KeyCode.__CONTEXTUAL} />
            ) : (
                <PINButton display="" value={KeyCode.__EMPTY} />
            )}
            <PINButton display="0" value={KeyCode.Zero} />
            <PINButton display={<StyledBackspace />} value={KeyCode.Backspace} />
        </PINButtonContainer>
    );
});

export const StyledBackspace = styled(Backspace).attrs(props => ({
    fill: props.theme.text,
    size: 48,
}))``;

export const StyledPINButton = styled.button``;

export const PINButtonContainer = styled.div<{appearance?: PinAppearance}>`
    margin-top: ${getSpacing(s8)};
    display: grid;
    display: -ms-grid;
    grid-template-columns: repeat(3, 33.333%);
    /**
     * Prettier adds an invalid whitespace. See https://github.com/prettier/prettier/issues/6375
     */
    /* prettier-ignore */
    -ms-grid-columns: (33.333%)[3];
    grid-template-rows: repeat(4, 80px);
    /* prettier-ignore */
    -ms-grid-rows: (80px)[4];

    ${StyledPINButton} {
        border: none;
        font-size: 36px;
        line-height: 1;
        font-weight: 600;
        color: ${props => props.theme.text};
        background: ${props => props.theme.invertedText};
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & > * {
            pointer-events: none;
        }

        border-top: 1px solid ${props => props.theme.textDisabled};
        border-right: 1px solid ${props => props.theme.textDisabled};
        &:nth-child(-n + 3) {
            border-top: none;
        }
        &:nth-child(3n) {
            border-right: none;
        }
        ${addHover(css`
            &:hover {
                background: ${props => props.theme.backgroundHover};
            }
        `)}

        &:focus {
            outline: none;
        }
    }

    ${media.mobile`
        ${StyledPINButton} {
            font-size: 32px;
        }
        grid-template-rows: repeat(4, 10vh);
        /* prettier-ignore */
        -ms-grid-rows: (10vh)[4];
    `}
    ${media.mobileSideways`
        ${StyledPINButton} {
            font-size: ${props => props.theme.fontConfiguration.sizes.Title} !important;
        }
        grid-template-rows: repeat(4, 10vh);
        /* prettier-ignore */
        -ms-grid-rows: (10vh)[4];
    `}

    /**
     * To make the grid layout work on IE we need to specify which column and row each
     * child belongs to..
     */
    ${Array.from({length: 4} /* Four rows */).flatMap((_, rowIdx) =>
        Array.from({length: 3} /* Three columns */).map(
            (_, columnIdx) => css`
                ${StyledPINButton}:nth-child(${rowIdx * 3 + columnIdx + 1}) {
                    -ms-grid-column: ${columnIdx + 1};
                    -ms-grid-row: ${rowIdx + 1};
                }
            `,
        ),
    )}
`;

const PINButton: React.FC<React.PropsWithChildren<{display: React.ReactNode; value: KeyCode}>> = ({display, value}) => {
    return (
        <StyledPINButton type="button" data-pin-value={value}>
            {display}
        </StyledPINButton>
    );
};
