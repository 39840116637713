import React from 'react';
import {type WrappedFieldProps} from 'redux-form';
import styled, {css} from 'styled-components';

import ReactOnMeta from 'web-app/react/components/form-elements/containers/react-on-meta';
import {Body, Caption, Subheader} from 'web-app/react/components/text/text';
import {Flex, type FlexProps} from 'web-app/react/components/layout/layout';
import Success from 'web-app/react/components/icons/legacy/lazy-icons/success';
import {s2} from 'web-app/styleguide/spacing';

import {SIZES, SIZE_MAP, makeInputSize, makeMarginSize} from '../helpers';

const CheckboxPartialIcon = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background-color: ${props => props.theme.invertedText};
    border-radius: 2px;
    height: 2px;
    width: calc(100% - 10px);
`;

export const StyledCheckbox = styled(({size, animate, partial, ...rest}) => <Flex {...rest} />)<
    {
        size: SIZES;
        noLabel: boolean;
        animate?: boolean;
        checked: boolean;
        partial?: boolean;
        noMargin?: boolean;
        disabled?: boolean;
    } & FlexProps
>`
    position: relative;
    height: ${props => makeInputSize(props.size)}px;
    width: ${props => makeInputSize(props.size)}px;
    border-radius: 2px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin: ${props => Math.round(makeMarginSize(props.size))}px;
    margin-right: 8px;
    flex-shrink: 0;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: inherit;
        border: 2px solid ${props => props.theme.text};
        z-index: 1;
    }

    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: ${props => props.theme.analogue2};
        ${props =>
            props.animate
                ? css`
                      transition: transform 0.2s ${props.theme.timings.functions.default};
                  `
                : ''}
        transform: translate(-50%, -50%) scale(0);
        box-sizing: border-box;
        overflow: hidden;
        z-index: 2;
    }

    & > * {
        z-index: 3;
        opacity: 0;
        ${props =>
            props.animate
                ? css`
                      transition: opacity 0.1s;
                  `
                : ''}
    }

    ${props =>
        props.checked || props.partial
            ? css`
                  &:before {
                      // Scales up so the circle exactly covers the square container
                      transform: translate(-50%, -50%)
                          scale(${Math.sqrt(2 * Math.pow(makeInputSize(props.size), 2)) / makeInputSize(props.size)});
                  }
                  & > * {
                      opacity: 1;
                  }
              `
            : ''}

    ${props =>
        props.noMargin
            ? css`
                  margin: ${makeMarginSize(props.size)}px;
              `
            : ''}

    ${props =>
        props.noLabel
            ? css`
                  margin-right: ${makeMarginSize(props.size)}px;
              `
            : ''}

    ${props =>
        props.disabled
            ? css`
                  opacity: 0.25;
              `
            : ''}
`;

const HiddenCheckboxInput = styled.input`
    position: absolute;
    top: 0;
    left: 0;
    height: 1px;
    width: 1px;
    pointer-events: none;
    opacity: 0;
    &:checked:focus + ${StyledCheckbox} {
        box-shadow: 0 0 0 1px ${props => props.theme.analogue3};
    }
    &:not(:checked):focus + ${StyledCheckbox}:after {
        border-color: ${props => props.theme.analogue3};
    }
`;
export const CheckboxWrapper = styled('label')<{size: SIZES; disabled?: boolean; align?: string}>`
    display: flex;
    align-items: flex-start;
    min-height: ${props => SIZE_MAP[props.size]}px;
    min-width: ${props => SIZE_MAP[props.size]}px;
    cursor: ${props => (props.disabled ? 'initial' : 'pointer')};
    position: relative;

    ${props =>
        props.align === 'top'
            ? css`
                  align-items: flex-start;
              `
            : ''}

    ${props =>
        props.disabled
            ? css`
                  cursor: auto;
              `
            : ''}

    @media all and (max-width: 500px) {
        cursor: pointer;
        overflow: hidden;

        ${props =>
            props.disabled
                ? css`
                      cursor: auto;
                  `
                : ''}
    }

    & > * {
        pointer-events: none;
    }
`;

type Align = 'top' | 'center';

interface CheckBoxProps {
    id?: string;
    input: any;
    onChange?: (checked: boolean) => void;
    inputClassName?: string;
    className?: string;
    checkboxBtnClassName?: string;
    checkboxTextClassName?: string;
    disabled?: boolean;
    secondary?: boolean;
    label?: React.ReactNode;
    subLabel?: string;
    align?: Align;
    ignoreResponsive?: boolean;
    noMargin?: boolean;
    partial?: boolean;
    description?: string;
    size?: SIZES;
    animate?: boolean;
    e2eId?: string; // deprecated
    ['data-e2e-id']?: string;
    ['data-intercom-target']?: string;
}

interface DefaultProps {
    align: string;
    size: SIZES;
    animate: boolean;
}

type OwnProps = CheckBoxProps & DefaultProps;

type FormCheckBoxProps = OwnProps & WrappedFieldProps;
type Props = OwnProps | FormCheckBoxProps;

const isFormCheckbox = (props: FormCheckBoxProps | OwnProps): props is FormCheckBoxProps => {
    return Object.prototype.hasOwnProperty.call(props, 'meta');
};

export class CheckBox extends React.PureComponent<Props> {
    public static defaultProps: DefaultProps = {
        align: 'center',
        size: SIZES.regular,
        animate: true,
    };

    public render() {
        if (isFormCheckbox(this.props)) {
            return <ReactOnMeta {...this.props}>{this.checkboxContent}</ReactOnMeta>;
        }
        return this.checkboxContent;
    }

    get checkboxContent() {
        const {
            id,
            label,
            input,
            disabled,
            size,
            align,
            className,
            partial,
            inputClassName,
            noMargin,
            checkboxBtnClassName,
            animate,
            e2eId,
        } = this.props;

        return (
            <CheckboxWrapper
                className={className}
                id={id}
                size={size}
                disabled={disabled}
                align={align}
                data-e2e-id={e2eId || this.props['data-e2e-id']}
                data-intercom-target={this.props['data-intercom-target']}
            >
                <HiddenCheckboxInput
                    {...input}
                    checked={Boolean(input.value)}
                    className={inputClassName}
                    disabled={disabled}
                    type="checkbox"
                    onChange={this.handleChange}
                    noMargin={noMargin}
                />
                <StyledCheckbox
                    className={checkboxBtnClassName}
                    checked={Boolean(input.value)}
                    partial={partial}
                    disabled={disabled}
                    align="center"
                    justify="center"
                    noLabel={!label}
                    size={size}
                    animate={animate}
                >
                    {partial ? <CheckboxPartialIcon /> : <Success size={makeInputSize(size)} appearance="white" />}
                </StyledCheckbox>
                {this.label()}
            </CheckboxWrapper>
        );
    }

    private handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (this.props.onChange) {
            this.props.onChange(e.target.checked);
        }
        return this.props.input.onChange && this.props.input.onChange(e);
    };

    private label() {
        const {label, disabled, secondary, checkboxTextClassName, description, size} = this.props;

        if (!label) {
            return null;
        }

        const TextComponent = this.textComponent(size);

        if (typeof label === 'string') {
            return (
                <div>
                    <TextComponent disabled={disabled} secondary={secondary} className={checkboxTextClassName}>
                        {label}
                    </TextComponent>
                    {description ? (
                        <Caption secondary={!disabled} disabled={disabled} marginBottom={s2}>
                            {description}
                        </Caption>
                    ) : null}
                </div>
            );
        } else {
            return label;
        }
    }

    private textComponent(size) {
        if (size === SIZES.small) {
            return Caption;
        } else if (size === SIZES.large) {
            return Subheader;
        } else {
            return Body;
        }
    }
}

export default CheckBox as React.ComponentType<React.PropsWithChildren<CheckBoxProps>>;
