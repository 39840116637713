import React from 'react';
import Box, {type BoxProps} from '@mui/material/Box';

import {useDataProps, type DataProps} from 'modern-famly/components/util';

import {ClassNames} from './shared';
import {type AnchorProps, getAnchorProps} from './anchor-props';
import {useListContext} from './list-context';

export type ListRowProps = {
    children?: React.ReactNode;
    isSelected?: boolean;
    onClick?: BoxProps['onClick'];
} & AnchorProps &
    DataProps;

export const ListRow = (props: ListRowProps) => {
    const anchorProps = getAnchorProps(props);

    const dataProps = useDataProps(props);

    const {forceDisplayContents, size} = useListContext();

    return (
        <Box
            data-checked={props.isSelected}
            {...anchorProps}
            onClick={props.onClick}
            sx={theme => ({
                display: 'contents',

                // We prefer to use subgrid if it's supported as display: contents gives accessibility issues
                // E.g. it's not possible to tab through list's rows
                ['@supports (grid-template-rows: subgrid)']: forceDisplayContents
                    ? {}
                    : {
                          gridTemplateColumns: 'subgrid',
                          gridColumn: '1 / -1',
                          display: 'grid',

                          // Not exactly sure why, but when adding an href to the row, the text of all the row's list items
                          // gets the underline styling by default. This is a workaround to remove it.
                          textDecoration: 'none',
                      },

                [`& > .${ClassNames.ListItem}`]: {
                    height: size === 'compact' ? '52px' : '64px',
                    backgroundColor: theme.modernFamlyTheme.colorPalette.n0,
                    borderTop: `1px solid ${theme.modernFamlyTheme.colorPalette.n75}`,
                    borderBottom: `1px solid ${theme.modernFamlyTheme.colorPalette.n75}`,
                    alignItems: 'center',
                    '&:first-of-type': {
                        borderLeft: `1px solid ${theme.modernFamlyTheme.colorPalette.n75}`,
                        borderTopLeftRadius: '8px',
                        borderBottomLeftRadius: '8px',
                    },

                    '&:last-of-type': {
                        borderRight: `1px solid ${theme.modernFamlyTheme.colorPalette.n75}`,
                        borderTopRightRadius: '8px',
                        borderBottomRightRadius: '8px',
                    },
                },
                [`&[data-checked="true"] > .${ClassNames.ListItem}`]: {
                    backgroundColor: theme.modernFamlyTheme.colorPalette.p50,
                },

                // If the row is a link, make it look clickable by adding hover styling on its immediate children
                ...(props.href || props.onClick
                    ? {
                          cursor: 'pointer',
                          // We don’t want hover effects on rows to be applied to the actions item, similarly when the action items
                          // are hovered, we don't want hover effect on the rest of the row. Selector reads as:
                          // When I'm hovered, and I _don't_ have a child with the class ListItemActions that is _also_ hovered,
                          // apply hover styles to all my direct children with the class ListItem that _don't_ have the class ListItemActions.
                          [`&:hover:not(:has(.${ClassNames.ListItemActions}:hover)) > .${ClassNames.ListItem}:not(.${ClassNames.ListItemActions})`]:
                              {
                                  backgroundColor: theme.modernFamlyTheme.colorPalette.p50,
                              },
                      }
                    : {}),
            })}
            {...dataProps}
        >
            {props.children}
        </Box>
    );
};
