import React, {type ReactNode, type FC} from 'react';
import classNames from 'classnames';
import i18next from 'i18next';
import styled, {css} from 'styled-components';

import TextTypes from 'web-app/styleguide/text-types';
import {media} from 'web-app/styleguide/utils';
import {ElementAppearance} from 'web-app/react/components/form-elements/containers/element-appearance';
import {TextBase, type TextProps, Caption} from 'web-app/react/components/text/text';
import Tooltip, {type TooltipProps} from 'web-app/react/components/tooltip/tooltip';
import {Flex, InlineBase} from 'web-app/react/components/layout/layout';
import {type BaseProps} from 'web-app/react/components/layout/layout';
import Info from 'web-app/react/components/icons/legacy/lazy-icons/info';
import {Border as DecorationInputBorder} from 'web-app/react/components/form-elements/containers/decorated-input/decorated-input';
import {type ITheme} from 'web-app/styleguide/themes/model';
import {getSpacing, s1, s2, s4} from 'web-app/styleguide/spacing';
import {type Colors} from 'web-app/styleguide/colors';
import {Container as TimePickerContainer} from 'web-app/react/components/form-elements/timepicker/timepicker';
import {getProperty, hasValue} from 'web-app/util/typescript';
import {type IconFill, type IconProps} from 'web-app/react/components/icons/types';

import {CheckboxWrapper} from '../checkbox/checkbox';

type Appearance = ElementAppearance | ElementAppearance[];

const appearanceHasRule = (appearance: Appearance | undefined, rule: Appearance): boolean => {
    if (rule instanceof Array) {
        return rule.every(r => appearanceHasRule(appearance, r));
    }
    if (appearance instanceof Array) {
        return appearance.includes(rule);
    }
    return appearance === rule;
};

interface AppearanceProps {
    isVertical: boolean;
    appearance?: Appearance;
    forceHorizontalLayout?: boolean;
}

export const ElementLabel = styled(({...rest}) => <TextBase as={'label'} {...rest} />).attrs(props => {
    let type = TextTypes.Body;
    if (appearanceHasRule(props.appearance, ElementAppearance.OnBackground)) {
        type = TextTypes.Body;
    }
    if (appearanceHasRule(props.appearance, ElementAppearance.Toolbar)) {
        type = TextTypes.Caption;
    }
    return {
        type,
    };
})<AppearanceProps & TextProps>`
    color: ${props => props.theme.textSecondary};
    ${props =>
        appearanceHasRule(props.appearance, [ElementAppearance.OnBackground, ElementAppearance.Toolbar])
            ? css`
                  &&&& {
                      margin-bottom: 8px;
                      line-height: 1;
                  }
              `
            : ''}
    ${props =>
        appearanceHasRule(props.appearance, ElementAppearance.OnBackground) ||
        appearanceHasRule(props.appearance, ElementAppearance.Toolbar)
            ? css`
                  color: ${props => props.theme.text};
                  ${props => props.theme.fontConfiguration.emphasized};
              `
            : ''}

    width: 180px;
    margin-top: 8px;
    padding-right: 6px;

    ${props =>
        props.isVertical &&
        css`
            width: auto;
            margin-top: 0;
            margin-bottom: 4px;
            line-height: 1;
        `}

    ${media.mobile`
        width: 80px;

        ${props =>
            !props.isHorizontal &&
            css`
                width: auto;
                margin-bottom: 8px;
            `}
    `}
`;

const ExplanationIcon = styled(Info).attrs(props => ({
    fill: props.theme.textDisabled,
}))`
    margin-top: -2px;
`;

const explanationTooltipAlignment = {
    points: ['bc', 'tr'],
    offset: [-8, -2],
};

interface StyledElementProps {
    noSpacing: any;
    noBorder: any;
    last: any;
    css?: ReturnType<typeof css>;
    hidden?: boolean;
}

export const StyledElement = styled(Flex)<AppearanceProps & BaseProps & StyledElementProps>`
    ${props =>
        appearanceHasRule(props.appearance, [ElementAppearance.OnBackground, ElementAppearance.Toolbar])
            ? css`
                  // Assume this means that the element is being used as a side panel with one element per row
                  &:first-child {
                      padding-top: 0;
                  }

                  padding-top: 16px;

                  && {
                      ${CheckboxWrapper} + ${CheckboxWrapper} {
                          margin-top: 4px;
                      }
                  }
              `
            : ''}

    ${props =>
        appearanceHasRule(props.appearance, [ElementAppearance.OnBackground, ElementAppearance.Toolbar]) &&
        !appearanceHasRule(props.appearance, ElementAppearance.OnWhiteBackground)
            ? css`
                  && {
                      // Element specific side effects, ie. remove borders
                      .Select:not(.is-focused) .Select-control,
                      input,
                      ${TimePickerContainer}, ${DecorationInputBorder} {
                          &:not(:focus) {
                              border-color: transparent;
                          }
                      }
                  }
              `
            : ''}

    padding: 8px 0;
    border-bottom: 1px solid ${props => props.theme.delimiter};

    ${props =>
        props.isVertical &&
        css`
            padding: 8px 0 14px 0;
            display: block;
        `}

    ${props =>
        props.noSpacing &&
        css`
            padding: 0;
            margin: 0;
        `}

    ${props =>
        props.noBorder &&
        css`
            padding-bottom: 0;
            border-bottom: none;
        `}

    ${props =>
        props.last &&
        css`
            border-bottom: none;
            margin-bottom: 10px;
        `}

    ${media.mobile`
        ${props =>
            props.noSpacing
                ? ''
                : css`
                      padding-top: 8px;
                      padding-bottom: 14px;
                  `}
        display: ${props => (props.forceHorizontalLayout ? 'flex' : 'block')};
    `}

    ${props =>
        !props.forceHorizontalLayout && appearanceHasRule(props.appearance, ElementAppearance.Toolbar)
            ? css`
                  ${StyledElement} + && {
                      padding-top: 16px;
                  }
              `
            : ''}

    ${props =>
        props.forceHorizontalLayout
            ? css`
                  ${StyledElement} + && {
                      margin-left: ${getSpacing(s4)};
                  }
              `
            : ''}
    ${props =>
        props.hidden
            ? css`
                  display: none;
              `
            : ''}
`;

export const FieldHolder = styled('div')<{fieldHolderCss: any}>`
    display: flex;
    flex: 1 1 0%;
    flex-wrap: wrap;

    ${props => props.fieldHolderCss}
`;

const TooltipContainer = styled(Flex)`
    flex-basis: 0%;
`;

interface IHelpText {
    text: string;
    textColor?: Colors;
    icon?: React.ComponentType<React.PropsWithChildren<IconProps>>;
    iconColor?: IconFill;
}

const HelpText = ({text, textColor, icon, iconColor}: IHelpText) => (
    <Flex marginTop={s1}>
        {icon ? <Flex marginRight={s1}>{React.createElement(icon, {fill: iconColor, size: 20})}</Flex> : null}
        <Caption color={textColor} disabled>
            {text}
        </Caption>
    </Flex>
);

export interface ElementProps {
    // Style related propTypes
    id?: string;
    appearance?: Appearance;
    border?: 'border' | 'none';
    forceHorizontalLayout?: boolean;
    noSpacing?: boolean;
    last?: boolean;
    forceVerticalLayout?: boolean;
    label?: ReactNode;
    className?: string;
    labelClass?: string;
    noLabel?: boolean;
    fieldHolderClasses?: string;
    fieldHolderCss?: any;
    explanation?: TooltipProps['overlay'];
    description?: string;
    required?: boolean;
    hidden?: boolean;
    css?: ReturnType<typeof css>;
    helpText?: IHelpText;
    ['data-e2e-id']?: string;
    ['data-intercom-target']?: string;
}

interface ThemeProps {
    theme?: ITheme;
}

const StyledAsterix = styled(InlineBase)`
    color: ${props => props.theme.mf.colorPalette.r400};
`;

export const Element: FC<React.PropsWithChildren<ElementProps & BaseProps & ThemeProps>> = props => {
    const {
        children,
        label,
        border = 'border',
        last,
        appearance,
        forceVerticalLayout,
        forceHorizontalLayout,
        noSpacing,
        noLabel,
        id,
        explanation,
        fieldHolderCss,
        theme,
        required,
        css,
        helpText,
        description,
        ...rest
    } = props;

    const elementAppearance = appearance || ElementAppearance.Toolbar;
    const isToolbar = appearanceHasRule(elementAppearance, ElementAppearance.Toolbar);
    const classes: {[key: string]: any} = {
        clearfix: true,
        [props.className!]: props.className,
    };

    const isVertical = forceVerticalLayout || isToolbar;
    const noBorder = border === 'none' || isToolbar;

    const getLabel = () => {
        if (noLabel) {
            return null;
        }

        const elementLabel = (
            <ElementLabel
                className={props.labelClass}
                appearance={elementAppearance}
                isVertical={isVertical}
                forceHorizontalLayout={forceHorizontalLayout}
                data-intercom-target={getProperty(props, 'data-intercom-target')}
            >
                {required ? <StyledAsterix>*</StyledAsterix> : null}
                {label}
                {/* required === false in on purpose since it is only when required is specifically set to false we want to do this */}
                {required === false ? <InlineBase marginLeft={s1}>({i18next.t('optional')})</InlineBase> : null}
            </ElementLabel>
        );

        if (explanation) {
            return (
                <Tooltip className="rc-tooltip-placement-top" overlay={explanation} align={explanationTooltipAlignment}>
                    <TooltipContainer justify="space-between">
                        {elementLabel}
                        <ExplanationIcon size={18} />
                    </TooltipContainer>
                </Tooltip>
            );
        } else {
            return elementLabel;
        }
    };

    return (
        <StyledElement
            id={id}
            appearance={elementAppearance}
            {...rest}
            className={classNames(classes)}
            forceHorizontalLayout={forceHorizontalLayout}
            isVertical={isVertical}
            noSpacing={noSpacing}
            noBorder={noBorder}
            last={last}
            css={css}
        >
            {getLabel()}
            {hasValue(description) ? (
                <StyledCaption secondary marginBottom={s2}>
                    {description}
                </StyledCaption>
            ) : null}
            <FieldHolder fieldHolderCss={fieldHolderCss} className={classNames(props.fieldHolderClasses)}>
                {children}
            </FieldHolder>
            {helpText ? <HelpText {...helpText} /> : null}
        </StyledElement>
    );
};

const StyledCaption = styled(Caption)`
    /* Allows line-breaks to be preserved (i.e. \n) */
    white-space: pre-line;
`;

export default Element;
