import React from 'react';
import MuiButton, {buttonClasses} from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Stack from '@mui/material/Stack';
import {styled} from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import Slide from '@mui/material/Slide';
import {ResizeObserver} from '@juggle/resize-observer';

import {Icon, type IconName, type IconProps, Text} from 'modern-famly/components/data-display';
import {Popper, type PopperProps} from 'modern-famly/components/data-display/popper';
import {type ColorKey, adjustAlpha} from 'modern-famly/theming';
import {type DataProps, useDataProps} from 'modern-famly/components/util';
import {useModernFamlyContext} from 'modern-famly/system/modern-famly-provider';
import {useMediaQuery} from 'modern-famly/theming/breakpoints';
import {useTranslation} from 'modern-famly/system/use-translation';

import {type ButtonProps} from '../button';
import {ButtonBase} from '../button/button-base';

export type DropdownButtonProps = ButtonProps & {
    /**
     * The options to display when the dropdown is opened
     */
    options: DropdownButtonOption[];

    /**
     * The preferred placement of the dropdown. Will only be respected if there's enough screen real estate to show it on that side.
     *
     * Defaults to "start".
     */
    dropdownPlacement?: 'start' | 'end';

    /**
     * Renders the options overlay in the node where the DropdownButton is rendered, instead of in a portal.
     * This can be used to solve display issues (e.g. when the DropdownButton is rendered inside an element with a specified z-index)
     */
    disablePortal?: boolean;
};

type DropdownButtonOptionAnchorProps =
    | {
          /**
           * Callback fired when the option is clicked
           */
          onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
          /**
           * The URL to link to when the button is clicked.
           * If defined, an `a` element will be used as the root node.
           */
          href?: undefined;
      }
    | {
          /**
           * Callback fired when the option is clicked
           */
          onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
          /**
           * The URL to link to when the button is clicked.
           * If defined, an `a` element will be used as the root node.
           */
          href: string;

          /**
           * Where to display the linked URL
           */
          target?: HTMLAnchorElement['target'];
      };

export type DropdownButtonOption = {
    /**
     * The label of the option
     */
    label: string;

    /**
     * The name or `{name, color}` of the icon to display next to the label
     */
    icon?: IconName | Pick<IconProps, 'name' | 'color'>;

    /**
     * The label and icon color
     */
    color?: ColorKey;

    /**
     * Disables the dropdown option, if set
     */
    disabled?: boolean;

    /**
     * The description of the option
     */
    description?: string;
} & DataProps &
    DropdownButtonOptionAnchorProps;

export const DropdownButton = (props: DropdownButtonProps) => {
    const {options, dropdownPlacement = 'start', ...buttonProps} = props;
    const [isOpen, setIsOpen] = React.useState(false);
    const anchorEl = React.useRef<HTMLButtonElement>(null);

    const onOptionClick = React.useCallback((e: React.MouseEvent<HTMLButtonElement>, option: DropdownButtonOption) => {
        setIsOpen(false);
        if (option.onClick) {
            option.onClick(e);
        }
    }, []);

    const useActionSheet = useShouldBehaveAsMobileApp();

    const buttonOptions = React.useMemo(
        () =>
            options.map((option, index) => (
                <DropdownButtonOptionRenderer
                    key={`option-${index}`}
                    option={option}
                    onOptionClick={onOptionClick}
                    useActionSheet={useActionSheet}
                />
            )),
        [onOptionClick, options, useActionSheet],
    );

    return (
        <>
            <ButtonBase
                {...buttonProps}
                onClick={event => {
                    props.onClick?.(event);
                    setIsOpen(isOpen => !isOpen);
                }}
                ref={anchorEl}
                endIcon={<Icon name="arrow_drop_down" size={24} />}
                // With the chevron displayed We need to offset the padding a
                // little so the weight of the button feels right
                sx={theme => ({
                    pr: theme.spacing(buttonProps.size === 'compact' ? 2.5 : 3.5),

                    // Fine-tune spacing between button text and arrow
                    [`.${buttonClasses.endIcon}`]: {
                        marginLeft: theme.modernFamlyTheme.spacing(0),
                    },
                })}
            />
            {useActionSheet ? (
                <MobileActionSheet
                    headerItemText={props.text}
                    isOpen={isOpen}
                    onClickAway={() => setIsOpen(false)}
                    disablePortal={props.disablePortal}
                >
                    {buttonOptions}
                </MobileActionSheet>
            ) : (
                <>
                    {anchorEl.current ? (
                        <DesktopDropdown
                            anchorEl={anchorEl.current}
                            isOpen={isOpen}
                            onClickAway={() => setIsOpen(false)}
                            placement={dropdownPlacement}
                            disablePortal={props.disablePortal}
                        >
                            {buttonOptions}
                        </DesktopDropdown>
                    ) : null}
                </>
            )}
        </>
    );
};

const DesktopDropdown = (props: {
    placement: DropdownButtonProps['dropdownPlacement'];
    isOpen: boolean;
    onClickAway: VoidFunction;
    anchorEl: HTMLElement;
    children: React.ReactNode;
    disablePortal?: boolean;
}) => {
    const placement: PopperProps['placement'] = props.placement === 'end' ? 'bottom-end' : 'bottom-start';

    // We want the dropdown to take up at least the same amount of
    // space as the button, so we observe the dimensions of the anchor
    // to to detect any size changes.
    const anchorElDimension = useElementDimension(props.anchorEl);

    return (
        <Popper
            placement={placement}
            open={props.isOpen}
            anchorEl={props.anchorEl}
            transition
            disablePortal={props.disablePortal}
        >
            {({TransitionProps}) => (
                <ClickAwayListener onClickAway={props.onClickAway}>
                    <Fade {...TransitionProps} timeout={200}>
                        <DropdownContainer minWidth={anchorElDimension.width} divider={<Divider />} role="listbox">
                            {props.children}
                        </DropdownContainer>
                    </Fade>
                </ClickAwayListener>
            )}
        </Popper>
    );
};

const MobileActionSheet = (props: {
    headerItemText?: string;
    isOpen: boolean;
    onClickAway: VoidFunction;
    children: React.ReactNode;
    disablePortal?: boolean;
}) => {
    const closeButtonText = useTranslation('DropdownButton.close');

    return (
        <Modal open={props.isOpen} disablePortal={props.disablePortal}>
            <Slide direction="up" in={props.isOpen}>
                <ModalPosition>
                    <ClickAwayListener onClickAway={props.onClickAway}>
                        <Stack gap={2}>
                            <DropdownContainer divider={<Divider />} role="dialog">
                                {props.headerItemText ? (
                                    <Text
                                        variant="body-small"
                                        emphasized
                                        color="n300"
                                        textAlign="center"
                                        height="48px"
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        {props.headerItemText}
                                    </Text>
                                ) : null}
                                {props.children}
                            </DropdownContainer>
                            <MuiButton variant="secondary" onClick={props.onClickAway}>
                                {closeButtonText}
                            </MuiButton>
                        </Stack>
                    </ClickAwayListener>
                </ModalPosition>
            </Slide>
        </Modal>
    );
};

const ModalPosition = styled('div')`
    position: absolute;
    bottom: calc(env(safe-area-inset-bottom) + 8px);
    left: 8px;
    right: 8px;
`;

const DropdownButtonOptionRenderer = ({
    option,
    onOptionClick,
    useActionSheet,
}: {
    option: DropdownButtonOption;
    onOptionClick: (e: React.MouseEvent<HTMLButtonElement>, option: DropdownButtonOption) => void;
    useActionSheet?: boolean;
}) => {
    const {label, onClick, color, icon, description, ...rest} = option;
    const iconName = typeof icon === 'string' ? icon : icon?.name;
    const iconColor = typeof icon === 'string' ? color : icon?.color;
    const anchorProps = rest.href
        ? {
              href: rest.href,
              as: 'a' as const,
              target: rest.target || undefined,
          }
        : {};
    const dataProps = useDataProps(rest);
    return (
        <DropdownOptionButton
            role={rest.href ? 'link' : 'option'}
            color={color}
            onClick={e => {
                e.stopPropagation();
                onOptionClick(e, option);
            }}
            // needs to be of type button or might otherwise submit modals with forms
            // https://famlyapp.slack.com/archives/C1PLD0U49/p1689603674665169
            type="button"
            disabled={rest.disabled}
            {...anchorProps}
            {...dataProps}
        >
            <Stack
                direction="row"
                gap={1}
                alignItems="center"
                justifyContent={useActionSheet ? 'center' : 'flex-start'}
                flexGrow="1"
            >
                {iconName && !useActionSheet ? <Icon size={18} name={iconName} color={iconColor} /> : null}
                <Stack direction="column" textAlign="start">
                    <Text variant={useActionSheet ? 'body' : 'body-small'}>{label}</Text>
                    {description && !useActionSheet && (
                        <Text
                            data-e2e-class="dropdown-option-description"
                            color="n300"
                            variant="micro"
                            maxWidth="60ch"
                            lines={2}
                        >
                            {description}
                        </Text>
                    )}
                </Stack>
            </Stack>
        </DropdownOptionButton>
    );
};

const DropdownContainer = styled(Stack)<{minWidth?: number}>`
    min-width: ${props => props.minWidth ?? 0}px;
    margin: ${props => props.theme.spacing(0.5, 0)};
    border: 1px solid ${props => props.theme.modernFamlyTheme.colorPalette.n75};
    border-radius: 8px;
    background-color: ${props => props.theme.modernFamlyTheme.colorPalette.n0};
    box-shadow: ${props => props.theme.modernFamlyTheme.elevation[2]};
    overflow: hidden;
`;

const DropdownOptionButton = styled('button')<{color?: ColorKey; disabled?: boolean}>`
    color: ${props => props.theme.modernFamlyTheme.colorPalette[props.color ?? 'n400']};
    font-size: ${props => props.theme.typography['body-small'].fontSize};
    font-family: ${props => props.theme.typography.fontFamily};
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    display: inline-flex;
    min-height: 48px;
    align-items: center;
    padding-left: ${props => props.theme.spacing(4)};
    padding-right: ${props => props.theme.spacing(4)};
    padding-top: ${props => props.theme.spacing(2)};
    padding-bottom: ${props => props.theme.spacing(2)};
    box-sizing: border-box;
    cursor: pointer;

    &:hover {
        background-color: ${props => props.theme.modernFamlyTheme.colorPalette.p50};
    }
    // This is needed in case href is passed in as a prop.
    // When that happens, the button acts as an anchor tag,
    // so we remove the underlining.
    text-decoration: none;

    &:disabled {
        background-color: ${props => props.theme.modernFamlyTheme.colorPalette.n75};
        color: ${props => adjustAlpha(0.6, props.theme.modernFamlyTheme.colorPalette.n300)};
    }
`;

type ElementDimensions = {
    width: number;
    height: number;
};

/**
 * Utility hook to observe the dimensions of the passed element. Whenever
 * the element changes size, the returned dimensions will update.
 */
const useElementDimension = <T extends HTMLElement>(element: T) => {
    const [dimensions, setDimensions] = React.useState<ElementDimensions>({width: 0, height: 0});

    const resizeObserver = React.useMemo(
        () =>
            new ResizeObserver(entries => {
                for (const entry of entries) {
                    const clientRect = entry.target.getBoundingClientRect();

                    setDimensions({width: clientRect.width, height: clientRect.height});
                }
            }),
        [],
    );

    // We use a layout effect here so we're sure that the
    // observer runs before the browser paints the screen
    React.useLayoutEffect(() => {
        resizeObserver.observe(element);

        return () => resizeObserver.unobserve(element);
    }, [element, resizeObserver]);

    return dimensions;
};

const useShouldBehaveAsMobileApp = () => {
    // Instead of using the nasty "customAtLeastOneLargerThanLandscape" breakpoint we hardcode the 1025px constant here.
    // This is in line with what the `useActionSheet` hook returns in app/react/components/action-sheet/action-sheet.tsx
    const shouldBehaveAsMobileApp = useMediaQuery(breakpoints => breakpoints.down(1025));
    const {isMobileApp} = useModernFamlyContext();

    return isMobileApp || shouldBehaveAsMobileApp;
};
