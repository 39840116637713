import type React from 'react';
import {css} from 'styled-components';

import renderStyle from 'web-app/styleguide/render-style';
import {ReactSelectCSS} from 'web-app/react/react-select-styles';

const GlobalCSS: React.FC = renderStyle(css`
    * {
        margin: 0;
        padding: 0;
    }
    /* Prevents native pull to refresh in iOS browsers */
    html,
    body {
        position: fixed;
        overflow: hidden;
    }
    body > #root {
        width: 100vw;
        height: 100vh;
        overflow-y: auto;
        overscroll-behavior: none; /* Prevents overscroll/bouncy scroll on iOS */
    }

    body {
        -webkit-touch-callout: none;
        -webkit-text-size-adjust: none;

        -ms-text-size-adjust: 100%; /* 2 */
        -webkit-text-size-adjust: 100%; /* 2 */

        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        -moz-tap-highlight-color: rgba(0, 0, 0, 0);

        font-family: 'Matter', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        margin: 0;
        padding: 0;
    }

    a {
        text-decoration: none;
    }

    ul {
        list-style-type: none;
        padding: 0;
    }

    button {
        outline: none;
    }

    ${ReactSelectCSS}
`);

export default GlobalCSS;
