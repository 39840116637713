import {type TrackingEvent} from '../types';

export const FoundationEvents = {
    // Creating a child from the room overview page
    ROOM_ADD_CHILD_CLICKED: {
        name: 'ROOM_ADD_CHILD_CLICKED',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },

    ROOM_CREATE_NEW_CHILD_CLICKED: {
        name: 'ROOM_CREATE_NEW_CHILD_CLICKED',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },

    // Creating an employee from the room overview page
    ROOM_ADD_EMPLOYEE_CLICKED: {
        name: 'ROOM_ADD_EMPLOYEE_CLICKED',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    ROOM_CREATE_NEW_EMPLOYEE_CLICKED: {
        name: 'ROOM_CREATE_NEW_EMPLOYEE_CLICKED',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    ROOM_ADD_EMPLOYEE_FROM_OTHER_ROOM_CLICKED: {
        name: 'ROOM_ADD_EMPLOYEE_FROM_OTHER_ROOM_CLICKED',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    ROOM_EMPLOYEE_CREATED: {
        name: 'ROOM_EMPLOYEE_CREATED',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    // Create rooms
    CREATE_ROOM_MODAL_OPENED: {
        name: 'CREATE_ROOM_MODAL_OPENED',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },

    // File upload
    FILE_UPLOAD_SUCCEEDED: {
        name: 'FILE_UPLOAD_SUCCEEDED',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    FILE_UPLOAD_FAILED: {name: 'FILE_UPLOAD_FAILED', sendTo: ['Mixpanel'], isActive: true, expirationDate: 'none'},

    // Navigation events
    NAVIGATION_GROUP_CLICKED: {
        name: 'Navigation: Navigation group clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    NAVIGATION_LINK_CLICKED: {
        name: 'Navigation: Navigation link clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    NAVIGATION_SUBLINK_CLICKED: {
        name: 'Navigation: Navigation sublink clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },

    // What's this events
    WHATS_THIS_OPENED: {
        name: "What's this: What's this opened",
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },

    // Settings page
    SETTINGS_OPENED: {
        name: 'Settings: Settings opened',
        sendTo: ['Mixpanel', 'Bucket'],
        isActive: true,
        expirationDate: 'none',
    },
    SETTINGS_TAB_OPENED: {name: 'Settings: Tab opened', sendTo: ['Mixpanel'], isActive: true, expirationDate: 'none'},
    SETTINGS_MY_ACCOUNT_OPENED: {
        name: 'Settings: My account opened',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },
    SETTINGS_FEATURE_FLAG_TOGGLED: {
        name: 'Settings: Feature flag toggled',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    SETTINGS_CHANGES_SAVED: {
        name: 'Settings: Changes saved in Settings',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    SETTINGS_SEARCHED_FOR_TERM: {
        name: 'Settings: Searched for term',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },

    /**
     * Disabled for later tracking when we decide to redo the settings section
     */
    SETTINGS_ROLE_EDIT_ENTER: {
        name: 'Settings: Role edit mode entered',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    SETTINGS_ROLE_EDITED: {
        name: 'Settings: Role edited',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    SETTINGS_ROLE_CREATED: {
        name: 'Settings: Role created',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },

    // Child creation
    CREATE_CHILD: {name: 'Child created', sendTo: ['Mixpanel'], isActive: true, expirationDate: 'none'},
    EDIT_CHILD_INFO: {
        name: 'Child information edited',
        sendTo: ['Mixpanel', 'Bucket'],
        isActive: true,
        expirationDate: 'none',
    },

    // Contact creation
    INVITE_CONTACTS: {name: 'Contacts invited', sendTo: ['Mixpanel'], isActive: true, expirationDate: 'none'},

    // Redux form submitted
    REDUX_FORM_SUBMITTED: {name: 'Form submitted', sendTo: ['Mixpanel'], isActive: true, expirationDate: 'none'},

    // Get started page
    GET_STARTED_BUTTON_CLICKED: {
        name: 'Get started: button clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },

    // Quick Search opened, whether by clicking button or using hotkey
    QUICK_SEARCH_MODAL_OPENED: {
        name: 'Quick Search: Opened',
        sendTo: ['Bucket'],
        isActive: true,
        expirationDate: 'none',
    },

    // Quick Search Chose result (either via enter or click)
    QUICK_SEARCH_CHOSE_RESULT: {
        name: 'Quick Search: Chose result',
        sendTo: ['Bucket'],
        isActive: true,
        expirationDate: 'none',
    },

    /**
     * Login Validation tracking disabled until we decide to tackle it again
     */
    LOGIN_VALIDATION_QUESTION: {
        name: 'Login Validation question',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    LOGIN_VALIDATION_FLOW_COMPLETED: {
        name: 'Login Validation flow completed',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },
    LOGIN_VALIDATION_ROLE_INVITATION_SENT: {
        name: 'Login Validation: role invitation sent',
        sendTo: ['Mixpanel'],
        isActive: false,
        expirationDate: 'none',
    },

    // Famly Home
    FAMLY_HOME_FAMLY_PAY_WIDGET_CLICKED: {
        name: 'Famly Home: Famly Pay widget clicked',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: '2023-12-31',
    },
    FAMLY_HOME_CUSTOMIZATION_SAVED: {
        name: 'Famly Home: Customization saved',
        sendTo: ['Bucket'],
        isActive: true,
        expirationDate: 'none',
    },

    // Lists, when the user hits the limited feature case
    LISTS_LIMITED_FEATURE: {
        name: 'Lists: Limited Feature',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },

    LISTS_VISITED: {
        name: 'Lists: List Visited',
        sendTo: ['Bucket'],
        isActive: true,
        expirationDate: 'none',
    },

    // Contact creation with or without login
    CONTACT_OR_LOGIN_CREATED: {
        name: 'Contact creation: Contact with or without login created or login added',
        sendTo: ['Mixpanel'],
        isActive: true,
        expirationDate: 'none',
    },

    // Document page
    DOCUMENTS_FOLDER_CREATED: {
        name: 'Documents: Folder Created',
        sendTo: ['Bucket'],
        isActive: true,
        expirationDate: 'none',
    },
    DOCUMENTS_FILE_ADDED: {
        name: 'Documents: File Added',
        sendTo: ['Bucket'],
        isActive: true,
        expirationDate: 'none',
    },
    DOCUMENTS_FILE_DOWNLOADED: {
        name: 'Documents: File Downloaded',
        sendTo: ['Bucket'],
        isActive: true,
        expirationDate: 'none',
    },

    // Custom reports
    CUSTOM_REPORT_CREATED: {
        name: 'Custom report: created',
        sendTo: ['Bucket'],
        isActive: true,
        expirationDate: 'none',
    },
    CUSTOM_REPORT_VISITED: {
        name: 'Custom report: visited',
        sendTo: ['Bucket'],
        isActive: true,
        expirationDate: 'none',
    },
    CUSTOM_REPORT_SHARED: {
        name: 'Custom report: shared',
        sendTo: ['Bucket'],
        isActive: true,
        expirationDate: 'none',
    },
    CUSTOM_REPORT_PRESET_SELECTED: {
        name: 'Custom report: preset selected',
        sendTo: ['Bucket'],
        isActive: true,
        expirationDate: 'none',
    },
    CUSTOM_REPORT_CREATE_BUTTON_CLICK: {
        name: 'Custom report: create button clicked',
        sendTo: ['Bucket'],
        isActive: true,
        expirationDate: 'none',
    },

    // Parental permissions
    PARENTAL_PERMISSION_CREATED: {
        name: 'Parental permission created',
        sendTo: ['Bucket'],
        isActive: true,
        expirationDate: 'none',
    },

    VILLAGE_JOIN: {
        name: 'Village: Join',
        sendTo: ['Bucket'],
        isActive: true,
        expirationDate: '2024-08-31',
    },
    VILLAGE_INVITE: {
        name: 'Village: Invite',
        sendTo: ['Bucket'],
        isActive: true,
        expirationDate: '2024-08-31',
    },

    // SMS Notifications
    SMS_SENT_FROM_POST: {
        name: 'SMS: Sent from post',
        sendTo: ['Bucket'],
        isActive: true,
        expirationDate: 'none',
    },
    SMS_SENT_FROM_MESSAGE: {
        name: 'SMS: Sent from message',
        sendTo: ['Bucket'],
        isActive: true,
        expirationDate: 'none',
    },
} satisfies Record<string, TrackingEvent>;
