import moment from 'moment-timezone';
import React from 'react';

import {useInterval} from 'web-app/react/hooks/use-interval';
import {type LeaveType} from 'web-app/react/leave-types/leave-type';
import {
    isPartDayLeave,
    type PersonStatus,
    type StatusDescription,
} from 'web-app/react/staffing-attendance/hooks/use-adapt-staff-hours-to-person-status-map';
import {hasValue} from 'web-app/util/typescript';

export function useCurrentPersonStatus(payload: {
    personStatus: PersonStatus | undefined;
    deprecatedStatus: {isSick: boolean; hasVacation: boolean; hasChildSick: boolean; isAbsent: boolean};
    timezone: string;
}): {[key in LeaveType]: StatusDescription} {
    const {timezone, personStatus, deprecatedStatus} = payload;
    const {
        isSick: deprecatedIsSick,
        hasVacation: deprecatedHasVacation,
        hasChildSick: deprecatedHasChildSick,
        isAbsent: deprecatedIsAbsent,
    } = deprecatedStatus ?? {};
    const currentTime = useCurrentTimeUpdating(timezone, false);
    let isSick: StatusDescription = [deprecatedIsSick];
    let hasVacation: StatusDescription = [deprecatedHasVacation];
    let hasChildSick: StatusDescription = [deprecatedHasChildSick];
    let isAbsent: StatusDescription = [deprecatedIsAbsent];

    if (personStatus) {
        isSick = personStatusInCurrentTime(personStatus.isSick, currentTime);
        hasVacation = personStatusInCurrentTime(personStatus.hasVacation, currentTime);
        hasChildSick = personStatusInCurrentTime(personStatus.hasChildSick, currentTime);
        isAbsent = personStatusInCurrentTime(personStatus.isAbsent, currentTime);
    }

    return {
        ['SICK']: isSick,
        ['VACATION']: hasVacation,
        ['CHILD_SICK']: hasChildSick,
        ['ABSENT']: isAbsent,
    };
}

function useCurrentTimeUpdating(timezone?: string, keepLocalTime?: boolean): string {
    function getMoment(): moment.Moment {
        const timeNow = moment();
        if (timezone) {
            timeNow.tz(timezone, keepLocalTime);
        }
        return timeNow;
    }

    // update the current time every minute, so that the sick status is updated correctly
    const [currentTime, setCurrentTime] = React.useState(() => getMoment().format('HH:mm'));
    const timeUpdateInterval = 1000 * 60;
    useInterval(() => setCurrentTime(() => getMoment().format('HH:mm')), timeUpdateInterval);
    return currentTime;
}

/**
 * Checks if the given Status description has an entry, which applies to the given currentTime string.
 *
 * Note: should not be used directly, only exposed for unit testing.
 *
 * @param statusDescription the status description to check for a correct entry
 * @param currentTime the current time in the `HH:mm` format
 * @returns
 *
 * `false`, if the statusdescription is undefined \
 * `false`, if any status in the description === false \
 * `true`, if any status in the description === true (full day of leave) \
 * `{from: string, to: string}`, the entity having the given current time in the `from - to` span
 */
export function personStatusInCurrentTime(
    statusDescription: StatusDescription,
    currentTime: string,
): StatusDescription {
    // as we are dealing with jsx files, better have this safety check here, although its not an optional parameter
    if (hasValue(statusDescription) && !statusDescription.some(status => status === false)) {
        const sickFullDay = statusDescription.some(status => status === true);
        if (sickFullDay) {
            return [true];
        }
        const partDayLeaves = statusDescription.filter(isPartDayLeave);
        for (const leave of partDayLeaves) {
            const {from, to} = leave;
            // creating a moment object from hours and minutes is not just ugly, but also quite costly.
            // as this is the status only for the day, wee need to compary only the hours and minutes, which
            // can be done in string comparision
            if (from < currentTime && currentTime < to) {
                return [leave];
            }
        }
    }
    return [false];
}
