import React from 'react';
import {styled} from '@mui/material/styles';
import MuiTooltip, {tooltipClasses, type TooltipProps as MuiTooltipProps} from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Stack from '@mui/material/Stack';
import Fade from '@mui/material/Fade';

import {Avatar} from 'modern-famly/components/data-display';
import {Icon, type IconName} from 'modern-famly/components/data-display/icon';
import {Text} from 'modern-famly/components/data-display/text';
import {type DataProps, useDataProps} from 'modern-famly/components/util';
import {useTranslation} from 'modern-famly/system/use-translation';
import {hasValue} from 'modern-famly/util';
import {useBreakpoints} from 'modern-famly/theming';
import {Sheet, type SheetProps} from 'modern-famly/components/navigation/drawer/drawer';

import {DropdownPillTestAttributes as TestAttributes} from './dropdown-pill-test-attributes';
import {Popper} from '../popper';

const MAX_NUMBER_OF_ITEMS_IN_TOOLTIP = 10;

export type DropdownPillProps = {
    /**
     * Label describing the items.
     */
    label: string;

    /**
     *  If set, overrides the default "(x) selected" that occurs when `items` is non empty
     */
    selectedLabel?: string;

    /**
     * Icon to be displayed.
     */
    icon?: IconName;

    /**
     * Items to display.
     */
    items?: ItemProps[];

    /**
     * Callback fired when the delete icon is clicked. If not set, the delete button will not be displayed.
     */
    onDelete?: () => void;

    /**
     * Callback fired when clicking outside of the popper.
     */
    onClickAway?: () => void;

    /**
     * Callback fired when clicking the pill.
     */
    onClick?: () => void;

    /**
     * Controls the open state of the popper. If set, the default functionality for opening and closing the popper will be ignored.
     */
    open?: boolean;

    /**
     * Used to render the children inside the dropdown after user clicks the pill.
     */
    children?: React.ReactNode;

    /**
     * Props passed to the Sheet component.
     * On small screens, the Sheet component is used instead of the Popper to render the children.
     */
    sheetProps?: Omit<SheetProps, 'open' | 'onOpen' | 'onClose'>;
} & DataProps;

export type ItemProps = {
    /**
     * Label describing the items.
     */
    label: string;

    /**
     * Avatar to be displayed next to person.
     */
    imageUrl?: string;
};

export const DropdownPill = ({
    label,
    selectedLabel,
    icon,
    onDelete,
    children,
    items,
    onClick,
    onClickAway,
    open,
    sheetProps,
    ...props
}: DropdownPillProps) => {
    const dataProps = useDataProps(props);

    const {isTabletLandscapeAndLarger} = useBreakpoints();

    const selectedItemsCount = items?.length ?? 0;

    const [isContentOpen, setContentOpen] = React.useState(false);

    // We use an effect here to ensure that the browser has had a chance
    // to initialize the pillRef before the popper is shown.
    React.useEffect(() => {
        if (hasValue(open)) {
            setContentOpen(open);
        }
    }, [open, setContentOpen]);

    const pillRef = React.useRef<HTMLDivElement | null>(null);

    const onContentClickAway = React.useCallback(() => {
        onClickAway?.();
        if (!hasValue(open)) {
            setContentOpen(false);
        }
    }, [onClickAway, open, setContentOpen]);

    const onPillClick: React.MouseEventHandler<HTMLDivElement> = React.useCallback(() => {
        onClick?.();
        if (!hasValue(open)) {
            setContentOpen(true);
        }
    }, [open, onClick, setContentOpen]);

    return (
        <>
            <DropdownPillContainer ref={pillRef} onClick={onPillClick} {...dataProps}>
                {hasValue(icon) ? <Icon name={icon} size={18} color="n300" /> : null}
                <Text variant="body" color="n400" marginX={1} data-e2e-class={TestAttributes.label}>
                    {label}
                </Text>

                <SelectedLabel shouldShowTooltip={!isContentOpen} selectedItems={items} selectedLabel={selectedLabel} />
                {!onDelete || selectedItemsCount === 0 ? (
                    <Icon name="arrow_drop_down" size={24} color="n400" />
                ) : (
                    <DeleteButton
                        onClick={e => {
                            // Stop propagation so clicking the delete button doesn't open the dropdown.
                            e.stopPropagation();
                            onDelete();
                        }}
                        data-e2e-class={TestAttributes.deleteButton}
                    >
                        <Icon name="close" size={20} color="n400" />
                    </DeleteButton>
                )}
            </DropdownPillContainer>
            {isTabletLandscapeAndLarger ? (
                <Popper
                    open={isContentOpen}
                    anchorEl={pillRef.current}
                    placement="bottom-start"
                    transition
                    // modifiers passed to Popper.js
                    modifiers={[
                        {
                            name: 'offset',
                            options: {
                                // [skidding, distance] — used to shift the popper from the default point of placement
                                // skidding is moving popper along the anchor element border
                                // distance is defining space between anchor element and popper
                                offset: [0, 4],
                            },
                        },
                    ]}
                >
                    {({TransitionProps}) => (
                        <ClickAwayListener onClickAway={onContentClickAway}>
                            <Fade {...TransitionProps} timeout={200}>
                                <PopperContainer role="presentation">{children}</PopperContainer>
                            </Fade>
                        </ClickAwayListener>
                    )}
                </Popper>
            ) : (
                <Sheet
                    open={isContentOpen}
                    onClose={onContentClickAway}
                    onOpen={onPillClick}
                    data-e2e-class={TestAttributes.sheet}
                    {...sheetProps}
                >
                    {children}
                </Sheet>
            )}
        </>
    );
};

const SelectedLabel = (props: {selectedLabel?: string; selectedItems?: ItemProps[]; shouldShowTooltip: boolean}) => {
    const {selectedLabel, selectedItems, shouldShowTooltip: shouldShowTooltipFromProps} = props;
    const selectedItemsCount = selectedItems?.length ?? 0;
    const selectedItemsLabel = useTranslation('DropdownPill.selected', `${selectedItemsCount}`);
    const shouldShowTooltip = shouldShowTooltipFromProps && selectedItemsCount > 0;

    // The `selectedLabel` takes precedence over everything else
    if (selectedLabel) {
        return (
            <Box marginRight={1}>
                <Text variant="body" color="p300" data-e2e-class={TestAttributes.selectedLabel}>
                    {selectedLabel}
                </Text>
            </Box>
        );
    }

    // If there are no selected items we don't show anything
    if (!selectedItems || selectedItemsCount === 0) {
        return null;
    }

    // If there are selected items we show the amount of items selected
    // and a tooltip displaying the selected items.
    return (
        <Tooltip items={selectedItems} shouldShow={shouldShowTooltip}>
            <Box marginRight={1}>
                <Text variant="body" color="p300" data-e2e-class={TestAttributes.selectedLabel}>
                    {selectedItemsLabel}
                </Text>
            </Box>
        </Tooltip>
    );
};

const PopperContainer = styled(Box)`
    background-color: ${({theme}) => theme.modernFamlyTheme.colorPalette.n0};
    border: 1px solid ${({theme}) => theme.modernFamlyTheme.colorPalette.n200};
    border-radius: ${({theme}) => theme.shape.borderRadius * 3}px;
    box-shadow: ${({theme}) => theme.modernFamlyTheme.elevation[2]};
    overflow: hidden;
`;

const Tooltip = (props: {shouldShow: boolean; items: ItemProps[]; children: MuiTooltipProps['children']}) => {
    const {shouldShow, items} = props;

    const itemsForTooltip = items.slice(0, MAX_NUMBER_OF_ITEMS_IN_TOOLTIP);
    const shouldShowMoreLabel = items.length > MAX_NUMBER_OF_ITEMS_IN_TOOLTIP;
    const moreText = useTranslation('DropdownPill.more', (items.length - MAX_NUMBER_OF_ITEMS_IN_TOOLTIP).toString());

    return (
        <SelectedTooltip
            role="tooltip"
            title={
                shouldShow ? (
                    <Stack gap={1} direction="column" marginY={2}>
                        {itemsForTooltip.map((item, index) =>
                            item.imageUrl ? (
                                <Stack
                                    key={index}
                                    gap={1}
                                    borderRadius="24px"
                                    height="20px"
                                    padding={0.5}
                                    sx={theme => ({
                                        backgroundColor: theme.modernFamlyTheme.colorPalette.n0,
                                    })}
                                    flexDirection="row"
                                    alignItems="flex-end"
                                >
                                    <Avatar src={item.imageUrl} size={20} />
                                    <Text variant="body-small" overflow="hidden" ellipsis>
                                        {item.label}
                                    </Text>
                                </Stack>
                            ) : (
                                <Text key={index} variant="body-small">
                                    {item.label}
                                </Text>
                            ),
                        )}
                        {shouldShowMoreLabel ? (
                            <Text variant="micro" color="n300" textAlign="center" marginTop={1}>
                                {moreText}
                            </Text>
                        ) : null}
                    </Stack>
                ) : null
            }
            arrow
            data-e2e-class={TestAttributes.tooltip}
        >
            {props.children}
        </SelectedTooltip>
    );
};

const SelectedTooltip = styled(({className, ...props}: MuiTooltipProps) => (
    <MuiTooltip {...props} classes={{popper: className}} />
))(({theme}) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.modernFamlyTheme.colorPalette.n50,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        color: theme.modernFamlyTheme.colorPalette.n400,
        backgroundColor: theme.modernFamlyTheme.colorPalette.n50,
        boxShadow: theme.modernFamlyTheme.elevation[3],
        width: 200,
    },
}));

const DropdownPillContainer = styled(Stack)`
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    background: ${({theme}) => theme.modernFamlyTheme.colorPalette.n50};
    padding: ${({theme}) => theme.modernFamlyTheme.spacing(1, 1, 1, 2)};
    border-radius: ${({theme}) => theme.shape.borderRadius * 6}px;
    cursor: pointer;

    &:hover {
        background: ${({theme}) => theme.modernFamlyTheme.colorPalette.p100};
    }
`;

const DeleteButton = styled('button')`
    margin: 0 !important;
    background: transparent !important;
    border: none !important;
    color: inherit !important;
    font-size: unset !important;

    padding: ${({theme}) => theme.modernFamlyTheme.spacing(0.5)} !important;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
`;
