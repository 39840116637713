import {Record} from 'immutable';

export type ButtonType = 'primary' | 'confirm';

interface IButton {
    background: string;
    color: string;
}

export class ButtonTypeConfiguration extends Record<IButton>({
    background: '',
    color: '',
}) {}

type ButtonConfigurationOptions = {[K in ButtonType]: ButtonTypeConfiguration} & {
    radius: number;
};

export class ButtonConfiguration extends Record<ButtonConfigurationOptions>({
    radius: 0,
    primary: new ButtonTypeConfiguration(),
    confirm: new ButtonTypeConfiguration(),
}) {}
