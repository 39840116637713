import React from 'react';
import i18next from 'i18next';
import {List} from 'immutable';
import styled, {css} from 'styled-components';
import {Box, Text} from 'modern-famly';

import {reduxForm} from 'web-app/react/deprecation/redux-form/redux-form';
import {Modal, ModalTopBar, ModalBody} from 'web-app/react/components/modals/modals';
import ModalHeader from 'web-app/react/components/modals/components/new-modal-header';
import {Element} from 'web-app/react/components/form-elements/containers/element/element';
import {Form} from 'web-app/react/components/form-elements/containers/form/form';
import {FormField} from 'web-app/react/components/form-elements/containers/form-field/form-field';
import {CheckBox} from 'web-app/react/components/form-elements/containers/checkbox/checkbox';
import translate from 'signin-app/helpers/translate';
import {s4, getSpacing} from 'web-app/styleguide/spacing';
import * as GroupsActions from 'signin-app/groups/actions';
import * as LocalStorage from 'signin-app/api/helpers/local-storage';
import {useTypedSelector} from 'signin-app/components/hooks';

import * as Actions from './actions';
import * as Selectors from './selectors';

/*
|---------------------------------------------------------------------------------
| Displayed-groups modal
|---------------------------------------------------------------------------------
*/

type FormData = {[groupId: string]: boolean};
const updateHiddenGroups = (formValues: FormData) => {
    const hiddenGroupIds = List(Object.keys(formValues).filter(groupId => !formValues[groupId]));
    LocalStorage.saveHiddenGroupIds(hiddenGroupIds);
    GroupsActions.updateHiddenGroups.dispatch(hiddenGroupIds);
    Actions.hide.dispatch();
};

const DisplayedGroupsSettingsModal: React.FC = () => {
    const groups = useTypedSelector(Selectors.groupsForFormData);

    const initialValues = React.useMemo(
        () =>
            groups.reduce((reduction, group) => {
                reduction[group.id] = group.value;
                return reduction;
            }, {}),
        [groups],
    );

    return (
        <>
            <StyledModal onCloseModal={Actions.hide.dispatch} type="custom" fitToViewport>
                <ModalTopBar onCloseModal={Actions.hide.dispatch}>
                    <ModalHeader header={translate('settings')} />
                </ModalTopBar>
                <StyledModalBody>
                    <Box px={4} pb={4}>
                        <Text variant="body-small" mb={1} emphasized>
                            {translate('settingsExplanation')}
                        </Text>
                        <FormWrapper onSubmit={updateHiddenGroups} initialValues={initialValues} groups={groups} />
                    </Box>
                </StyledModalBody>
            </StyledModal>
        </>
    );
};

const FormWrapper = reduxForm<FormData, {groups: ReturnType<typeof Selectors.groupsForFormData>}>({
    form: 'DISPLAYED_GROUPS_SETTINGS',
})(({handleSubmit, groups}) => {
    return (
        <Form
            onSubmit={handleSubmit}
            submitText={i18next.t('save')}
            submitButtonPosition={'fill'}
            buttonContainerCSS={buttonContainerCSS}
        >
            {groups.map(group => (
                <Element key={group.id}>
                    <FormField component={CheckBox} name={group.id} label={group.title} />
                </Element>
            ))}
        </Form>
    );
});

/*
|---------------------------------------------------------------------------------
| Components
|---------------------------------------------------------------------------------
*/

const StyledModal = styled(Modal)`
    width: 432px;

    && {
        height: auto;
    }
`;

const StyledModalBody = styled(ModalBody)`
    overflow-y: auto;
    padding-bottom: 0;
`;

const buttonContainerCSS = css`
    position: sticky;
    bottom: 0;
    z-index: 3;
    background: ${props => props.theme.invertedText};
    margin: 0;
    margin-top: ${getSpacing(s4)};
    font-size: ${props => props.theme.fontConfiguration.sizes.Body};
    font-weight: ${props => props.theme.fontConfiguration.emphasized};
`;

export default DisplayedGroupsSettingsModal;
