import React from 'react';
import PropTypes from 'prop-types';

import Textarea from 'web-app/react/components/form-elements/text-area/text-area';

import ReactOnMeta from './react-on-meta';

export class FormTextArea extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }
    render() {
        const {
            meta, // eslint-disable-line no-unused-vars
            input,
            ...rest
        } = this.props;

        const inputProps = {
            ...input,
            ...rest,
            onChange: this.handleChange,
        };

        return (
            <ReactOnMeta {...this.props}>
                <Textarea {...inputProps} />
            </ReactOnMeta>
        );
    }
    handleChange(e, ...args) {
        if (this.props.onChange) {
            this.props.onChange(e);
        }
        this.props.input.onChange(e, ...args);
    }
}

FormTextArea.propTypes = {
    meta: PropTypes.object,
    input: PropTypes.object,
    onChange: PropTypes.func,
};

export default FormTextArea;
