import {Record} from 'immutable';

import hexToRGB from 'web-app/util/hex-to-rgb';

type Shadow = 'zero' | 'half' | 'one' | 'two' | 'three' | 'four' | 'five';

type ShadowDirection = 'up' | 'down' | 'left' | 'right';

export interface IShadowConfiguration {
    readonly zero: string;
    readonly half: string;
    readonly one: string;
    readonly two: string;
    readonly three: string;
    readonly four: string;
    readonly five: string;
    readonly getShadow: (shadow: Shadow, direction: ShadowDirection) => string;
    readonly color: string;
}

export class ShadowConfiguration extends Record<IShadowConfiguration>({
    zero: '',
    half: '',
    one: '',
    two: '',
    three: '',
    four: '',
    five: '',
    getShadow: () => '',
    color: '',
}) {}

const getShadowSize = (shadow: Shadow) => {
    switch (shadow) {
        case 'half':
            return 1;
        case 'two':
            return 4;
        case 'three':
            return 8;
        case 'four':
            return 16;
        case 'five':
            return 24;
        case 'one':
        default:
            return 2;
    }
};

const getShadowPosition = (shadow: Shadow, direction: ShadowDirection) => {
    const size = getShadowSize(shadow);

    const blur = size * 2;

    const directionalSize = (direction === 'right' || direction === 'up' ? -1 : 1) * size;

    if (direction === 'right' || direction === 'left') {
        return `${directionalSize}px 0 ${blur}px 0`;
    } else {
        return `0 ${directionalSize}px ${blur}px 0`;
    }
};

const getShadow = (shadow: Shadow, direction: ShadowDirection, shadowBlack: string) => {
    const position = getShadowPosition(shadow, direction);

    return `${position} ${shadowBlack}`;
};

const getShadows = (black: string) => {
    const rgbBlack = hexToRGB(black);

    if (!rgbBlack) {
        return new ShadowConfiguration();
    }

    const shadowBlack = `rgba(${rgbBlack.r}, ${rgbBlack.g}, ${rgbBlack.b}, 0.1)`;

    return new ShadowConfiguration({
        zero: 'none',
        half: getShadow('half', 'down', shadowBlack),
        one: getShadow('one', 'down', shadowBlack),
        two: getShadow('two', 'down', shadowBlack),
        three: getShadow('three', 'down', shadowBlack),
        four: getShadow('four', 'down', shadowBlack),
        five: getShadow('five', 'down', shadowBlack),
        getShadow: (shadow: Shadow, direction: ShadowDirection) => getShadow(shadow, direction, shadowBlack),
        color: shadowBlack,
    });
};

export default getShadows;
