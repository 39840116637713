import {type QueryResult} from '@apollo/client';
import React from 'react';

import {type LeaveType} from 'web-app/react/leave-types/leave-type';
import {exhaustiveCheck} from 'web-app/util/typescript';

import {type FetchStaffHoursQuery} from '../graphql/queries/__generated__/fetch-staff-hours.query.api-types';
import {type GroupRecordsByDayFragment} from '../graphql/fragment/generics/__generated__/group-records-by-day.fragment.api-types';

type FullDay = boolean;
type PartDay = {
    from: string;
    to: string;
};

type DayStatus = FullDay | PartDay;

export type StatusDescription = DayStatus[];

export interface PersonStatus {
    isSick: StatusDescription;
    hasVacation: StatusDescription;
    hasChildSick: StatusDescription;
    isAbsent: StatusDescription;
}
export interface PersonStatusMap {
    employeeId: string;
    groupId: string;
    date: string;
    personStatus: PersonStatus;
}

export function isPartDayLeave(statusDescription: DayStatus): statusDescription is PartDay {
    return (statusDescription as PartDay).from !== undefined;
}

export function useAdaptStaffHoursToPersonStatusMap(
    queryResult: QueryResult<FetchStaffHoursQuery>,
): PersonStatusMap[] | undefined {
    return React.useMemo(() => {
        const {data} = queryResult;
        if (!data) {
            return undefined;
        }
        return data.staffhours.byInstitution.roomsReport.reduce((result: PersonStatusMap[], room) => {
            const groupId = room.room.id;
            room.staffRecords.forEach(staffRecord => {
                const employeeId = staffRecord.staffMember.id;
                staffRecord.recordsByDay
                    .filter(
                        (recordByDay): recordByDay is GroupRecordsByDayFragment =>
                            recordByDay.__typename === 'GroupRecordsByDay',
                    )
                    .forEach((record: GroupRecordsByDayFragment) => {
                        const date = record.date;
                        const isSickStati: StatusDescription = [];
                        const hasVacationStati: StatusDescription = [];
                        const hasChildSickStati: StatusDescription = [];
                        const isAbsentStati: StatusDescription = [];

                        record.leaves
                            // eslint-disable-next-line max-nested-callbacks
                            .filter(leave => !leave.deleted)
                            // eslint-disable-next-line max-nested-callbacks
                            .forEach(leave => {
                                const statusFromLeave = getStatusFromLeave(leave);
                                const leaveType: LeaveType = leave.leaveType as LeaveType;
                                switch (leaveType) {
                                    case 'SICK':
                                        isSickStati.push(statusFromLeave);
                                        break;
                                    case 'VACATION':
                                        hasVacationStati.push(statusFromLeave);
                                        break;
                                    case 'ABSENT':
                                        isAbsentStati.push(statusFromLeave);
                                        break;
                                    case 'CHILD_SICK':
                                        hasChildSickStati.push(statusFromLeave);
                                        break;
                                    default:
                                        exhaustiveCheck(leaveType);
                                        console.warn('encountered unknown leave type!', leave.leaveType);
                                }
                            });
                        result.push({
                            groupId,
                            employeeId,
                            date,
                            personStatus: {
                                isSick: isSickStati.length === 0 ? [false] : isSickStati,
                                hasVacation: hasVacationStati.length === 0 ? [false] : hasVacationStati,
                                hasChildSick: hasChildSickStati.length === 0 ? [false] : hasChildSickStati,
                                isAbsent: isAbsentStati.length === 0 ? [false] : isAbsentStati,
                            },
                        });
                    });
            });
            return result;
        }, [] as PersonStatusMap[]);
    }, [queryResult]);
}

function getStatusFromLeave(leave: {startTime?: string | null; endTime?: string | null}):
    | boolean
    | {
          from: string;
          to: string;
      } {
    if (leave.startTime && leave.endTime) {
        // the date format _should_ come in 'YYYY-MM-DDTHH:mm' format, but might just be 'HH:mm', too.
        // so let's just allow both here and better be safe than sorry
        const from = leave.startTime.includes('T') ? leave.startTime.split('T')[1] : leave.startTime;
        const to = leave.endTime.includes('T') ? leave.endTime.split('T')[1] : leave.endTime;
        return {from, to};
    }
    return true;
}
