import {type Components, type SxProps, type Theme, type ThemeOptions} from '@mui/material/styles';
import Typography, {type TypographyProps} from '@mui/material/Typography';
import React from 'react';

import {extractSystemStyles, type WithSystemProps} from '../../../system';
import {getEmphasizedFontWeight, useDataProps} from '../../util';

export type TextProps = {
    children: TypographyProps['children'];
    ellipsis?: boolean;
    emphasized?: boolean;
    whiteSpace?: TypographyProps['whiteSpace'];
    variant: TypographyProps['variant'];

    /**
     * When set, the text will be displayed with a maximum number of lines.
     * If the text exceeds the number of lines, it will be truncated and an ellipsis will be displayed.
     */
    lines?: number;

    /**
     * When set, the text will be displayed as a number.
     * Note that this will not apply any formatting — only the font itself and some font-attributes will be changed.
     */
    numeric?: boolean;
};

export const Text = (props: WithSystemProps<TextProps>) => {
    const {variant, children, emphasized, ellipsis, numeric, lines, ...rest} = props;

    const dataProps = useDataProps(rest);
    const {sx: systemSx} = extractSystemStyles(rest);

    const sx = React.useCallback<Extract<SxProps<Theme>, Function>>(
        theme => {
            return {
                ...systemSx(theme),
                fontWeight: emphasized ? getEmphasizedFontWeight(variant) : undefined,
                ...getNumericStyle(numeric),
                ...(!props.ellipsis &&
                    lines && {
                        display: '-webkit-box',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: lines,
                    }),
            };
        },
        [systemSx, emphasized, variant, numeric, props.ellipsis, lines],
    );

    return (
        <Typography sx={sx} variant={variant} noWrap={props.ellipsis} {...dataProps}>
            {props.children}
        </Typography>
    );
};

const getNumericStyle = (isNumeric?: boolean) => {
    return isNumeric
        ? {
              fontFamily: 'Inter',
              fontVariantNumeric: 'lining-nums tabular-nums stacked-fractions slashed-zero',
              fontFeatureSettings: "'ss02' on, 'ss04' on, 'cv11' on, 'ss01' on",
          }
        : {};
};

/*
|------------------------------------------------------------------------------
| MUI theming
|------------------------------------------------------------------------------
*/
declare module '@mui/material/styles' {
    interface TypographyVariants {
        poster: React.CSSProperties;
        body: React.CSSProperties;
        'body-small': React.CSSProperties;
        micro: React.CSSProperties;
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        poster?: React.CSSProperties;
        body?: React.CSSProperties;
        'body-small'?: React.CSSProperties;
        micro?: React.CSSProperties;
    }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        body: true;
        'body-small': true;
        micro: true;
        subtitle1: false;
        subtitle2: false;
        body1: false;
        body2: false;
        button: false;
        caption: false;
        overline: false;
        inherit: false;
    }
}

export const TypographyThemeOptions: ThemeOptions['typography'] = {
    fontFamily: 'Matter, "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif',
    h1: {
        fontSize: '64px',
        fontWeight: 400,
        lineHeight: '72px',
        letterSpacing: '-0.01em',
    },
    h2: {
        fontSize: '48px',
        fontWeight: 400,
        lineHeight: '56px',
        letterSpacing: '-0.01em',
    },
    h3: {
        fontSize: '40px',
        fontWeight: 400,
        lineHeight: '48px',
        letterSpacing: '-0.01em',
    },
    h4: {
        fontSize: '32px',
        fontWeight: 400,
        lineHeight: '40px',
        letterSpacing: '-0.01em',
    },
    h5: {
        fontSize: '24px',
        fontWeight: 400,
        lineHeight: '32px',
        letterSpacing: '-0.01em',
    },
    h6: {
        fontSize: '20px',
        fontWeight: 400,
        lineHeight: '28px',
        letterSpacing: '-0.01em',
    },
    body: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '20px',
    },
    'body-small': {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '18px',
    },
    micro: {
        fontFamily: 'Inter',
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '16px',
        letterSpacing: '-0.02em',
    },
};

export const TypographyThemeConfiguration: Components<Theme>['MuiTypography'] = {
    defaultProps: {
        variantMapping: {
            h1: 'h1',
            h2: 'h2',
            h3: 'h3',
            h4: 'h4',
            h5: 'h5',
            h6: 'h6',
            body: 'p',
            'body-small': 'p',
            micro: 'p',
        },
    },
};
