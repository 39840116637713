import {List} from 'immutable';
import {BaseTheme as BaseMFTheme} from 'modern-famly';

import hexToRGB, {hexToRGBA, hexToRGBString} from 'web-app/util/hex-to-rgb';
import {
    type OneOffColor,
    ColorConfiguration,
    ChildDevelopmentColors,
    FrameworkColors,
    RefinementColors,
    FrameworkColorId,
    NewColors,
} from 'web-app/styleguide/colors';
import {type ElevationConfiguration} from 'web-app/styleguide/elevations';
import {ContainerConfiguration} from 'web-app/styleguide/containers';
import {NavigationConfiguration} from 'web-app/styleguide/navigation-configuration';
import {BorderConfiguration} from 'web-app/styleguide/borders';
import {InformationArchitectureConfiguration} from 'web-app/styleguide/information-architecture';
import {ButtonConfiguration, ButtonTypeConfiguration} from 'web-app/styleguide/components/buttons';

import getShadows from '../shadows';
import {BaseFontConfiguration} from '../fonts';
import {Theme} from './model';

const FamlyPrimary = '#5C34A3';
const FamlyActive = '#906CD0';

const White = '#FFFFFF';
const rgbWhite = hexToRGB(White)!;

const Black = '#120A20';
const rgbBlack = hexToRGB(Black)!;
const rgbStringBlack = hexToRGBString(Black)!;

const Primary = '#4B4F59';
const Secondary = '#8159CA';
const ChromeColor = Primary;
const Active = hexToRGBA(Primary, 0.8);
const Accent1 = '#17CF48';
const Accent2 = '#FFCA3C';
const Accent3 = '#E91D62';
const Analogue1 = '#3E50B4';
const Analogue2 = '#2195F2';
const Analogue3 = '#1ACAE6';
const Analogue4 = '#2095f2';
const TextDisabled = `rgba(${rgbBlack.r}, ${rgbBlack.g}, ${rgbBlack.b}, 0.35)`;

const elevationConfiguration: ElevationConfiguration = {
    one: BaseMFTheme.elevation[1],
    two: BaseMFTheme.elevation[2],
    three: BaseMFTheme.elevation[3],
    four: BaseMFTheme.elevation[4],
    five: BaseMFTheme.elevation[5],
    six: BaseMFTheme.elevation[6],
};

let BaseTheme = new Theme().merge({
    title: 'Base',
    primary: Primary,
    secondary: Secondary,
    chromeColor: ChromeColor,
    active: Active,
    accent1: Accent1,
    accent2: Accent2,
    accent3: Accent3,
    analogue1: Analogue1,
    analogue2: Analogue2,
    analogue3: Analogue3,
    analogue4: Analogue4,
    text: Black,
    blackScrim: `rgba(${rgbBlack.r}, ${rgbBlack.g}, ${rgbBlack.b}, 0.75)`,
    textSecondary: `rgba(${rgbBlack.r}, ${rgbBlack.g}, ${rgbBlack.b}, 0.6)`,
    textDisabled: TextDisabled,
    invertedText: White,
    invertedTextSecondary: `rgba(${rgbWhite.r}, ${rgbWhite.g}, ${rgbWhite.b}, 0.75)`,
    invertedTextDisabled: `rgba(${rgbWhite.r}, ${rgbWhite.g}, ${rgbWhite.b}, 0.5)`,
    delimiter: `rgba(${rgbBlack.r}, ${rgbBlack.g}, ${rgbBlack.b}, 0.08)`,
    background: '#f4f4f4',
    backgroundHover: hexToRGBA(Primary, 0.04),
    // Subtle greyish background
    applicationBackground: '#fbfbfb',
    shadow: getShadows(Black),
    fontConfiguration: BaseFontConfiguration,
    containerConfiguration: new ContainerConfiguration({
        borderRadius: '8px',
        contentAreaBorder: '1px solid rgba(18, 10, 32, 0.08)',
        tableBorder: '2px solid rgba(242, 242, 242, 1)',
        popUpsAndLinksBorder: '1px solid rgba(18, 10, 32, 0.08)',
        popUpsAndLinksBoxShadow: '0 4px 8px 0 rgba(18, 10, 32, 0.1)',
        tabSeparatorBackground:
            'linear-gradient( to bottom, rgba(255,255,255,0) 90%, rgba(255,255,255,0.02) 86%, rgba(237,237,237,0.65) 100% )',
        border: '1px solid #f6f7f7',
        boxShadow2: '0px 3px 2px 0px #172b4d14, 0px 0px 2px 0px #172b4d0a',
    }),
    borderConfiguration: new BorderConfiguration({
        defaultColor: `rgba(${rgbStringBlack}, 0.125)`,
        hover: `rgba(${rgbStringBlack}, 0.25)`,
        focus: Analogue2,
        error: Accent3,
        disabled: `rgba(${rgbStringBlack}, 0.1)`,
    }),
    buttonConfiguration: new ButtonConfiguration({
        radius: 7,
        primary: new ButtonTypeConfiguration({
            background: FamlyPrimary,
            color: White,
        }),
        confirm: new ButtonTypeConfiguration({
            background: FamlyPrimary,
            color: White,
        }),
    }),
    informationArchitectureConfiguration: new InformationArchitectureConfiguration({
        tabBarHeightMobile: 56,
        tabBarHeightTablet: 80,
        tabBarHeightDesktop: 0,
        navigationBarHeightDesktop: 84,
    }),
    navigationConfiguration: new NavigationConfiguration({
        contextualSidebarBackground: '#f8f8f8',
        contextualSidebarLinkBackground: '#E9EAEC',
        contextualSidebarLinkColor: NewColors.Neutral.s80,
        contextualSidebarLinkColorActive: NewColors.Neutral.s80,
    }),
    colors: new ColorConfiguration({
        ChildDevelopment: new ChildDevelopmentColors({
            FrameworkColors: new FrameworkColors({
                [FrameworkColorId.EYFS]: List([
                    Analogue2,
                    Accent2,
                    Accent3,
                    TextDisabled,
                    FamlyActive,
                    Accent1,
                    FamlyPrimary,
                    '#FF7705',
                    '#1ACAE6',
                ]),
                [FrameworkColorId.CoEL]: List(['#D1BCD1', '#B5E6D6', '#ffd692']),
                [FrameworkColorId.CfE]: List([
                    Analogue2,
                    Accent2,
                    Accent3,
                    `rgba(${rgbStringBlack}, 0.25)`,
                    '#c4c2c6',
                    Accent1,
                    FamlyPrimary,
                ]),
                [FrameworkColorId.Espira2018]: List([
                    '#115DA9',
                    '#EEAB00',
                    '#D72C88',
                    '#80BB27',
                    '#40B5E6',
                    '#FCE300',
                    '#904996',
                ]),
            }),
            RefinementColors: new RefinementColors({
                EMERGING: `rgba(${rgbStringBlack}, 0.25)`,
                DEVELOPING: Accent1,
                SECURE: '#0B8934',
            }),
        }),
    }),
    elevation: elevationConfiguration,
    mf: BaseMFTheme,
});

BaseTheme = BaseTheme.merge({
    convertOneOffColor: (oneOffColor: OneOffColor) => oneOffColor,
});

export default BaseTheme;
