import React from 'react';
import Box from '@mui/material/Box';
import type CSSType from 'csstype';

import {useBreakpoints} from 'modern-famly/theming';
import {useDataProps, type DataProps} from 'modern-famly/components/util';

import {type ListBreakpoint} from './use-list-media-query';
import {ListContext} from './list-context';

type OptionalListBreakpoint = Exclude<ListBreakpoint, 'tabletPortrait'>;
type BreakpointsValueForLists<T> = {tabletPortrait: T} & {[B in OptionalListBreakpoint]?: T};

export type ListProps = {
    children?: React.ReactNode;
    /**
     * Indicates the height of each row, compact is 52 px and regular is 64 px.
     */
    size?: 'compact' | 'regular';
    gridTemplateColumns:
        | BreakpointsValueForLists<CSSType.Property.GridTemplateColumns>
        | CSSType.Property.GridTemplateColumns;
    /**
     * When `true` forces the `display: contents` CSS property on child ListRow components.
     */
    forceDisplayContents?: boolean;
} & DataProps;

export const List = (props: ListProps) => {
    const {isTabletPortraitAndLarger} = useBreakpoints();

    const dataProps = useDataProps(props);

    const ListContextValue = React.useMemo(
        () => ({
            forceDisplayContents: props.forceDisplayContents || false,
            device: 'non-mobile' as const,
            size: props.size || 'compact',
        }),
        [props.forceDisplayContents, props.size],
    );

    if (!isTabletPortraitAndLarger) {
        return null;
    }

    return (
        <ListContext.Provider value={ListContextValue}>
            <Box
                sx={{
                    width: '100%',
                    display: 'grid',
                    // We're "injecting" a grid area name here to make it possible to use subgrid in the ListRow component
                    gridTemplateColumns: props.gridTemplateColumns,
                    rowGap: '1px',
                }}
                {...dataProps}
            >
                {props.children}
            </Box>
        </ListContext.Provider>
    );
};
