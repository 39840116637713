import React, {type PropsWithChildren} from 'react';
import Box, {type BoxProps} from '@mui/material/Box';

import {type DataProps, useDataProps} from 'modern-famly/components/util';

import {useListMediaQuery, type ListBreakpoint} from './use-list-media-query';
import {ClassNames} from './shared';
import {useListContext} from './list-context';

export type ListItemProps = {
    /**
     * Which breakpoint this list item should be displayed from.
     *
     * Ignored on mobile.
     *
     * @default 'tabletPortrait'
     */
    displayFrom?: ListBreakpoint;

    /**
     * Sets the text alignment of the list item.
     */
    textAlign?: BoxProps['textAlign'];

    /**
     * The function to call when the list item is clicked.
     *
     * Ignored on mobile.
     */
    onClick?: React.MouseEventHandler<HTMLDivElement>;

    /**
     * Sets the horizontal padding of the list item.
     *
     * Defaults to 16px on desktop and 0px on mobile, but can be overridden.
     */
    px?: BoxProps['px'];

    /**
     * Sets the minimum width of the list item. This can be necessary in flex
     * containers to ensure that items can ellipsis correctly.
     */
    minWidth?: BoxProps['minWidth'];

    /**
     * Additional class name to apply to the list item.
     */
    className?: string;
} & DataProps;

export const ListItem = ({
    displayFrom = 'tabletPortrait',
    textAlign,
    onClick,
    children,
    px = 4,
    minWidth,
    className,
    ...rest
}: PropsWithChildren<ListItemProps>) => {
    const {device} = useListContext();
    const isVisible = useListMediaQuery(displayFrom) || device === 'mobile';

    const _px = device === 'mobile' ? 0 : px;

    const dataProps = useDataProps(rest);

    return (
        <Box
            className={`${ClassNames.ListItem} ${className ?? ''}`}
            display={isVisible ? 'inline-flex' : 'none'}
            textAlign={textAlign}
            onClick={device === 'non-mobile' ? onClick : undefined}
            px={_px}
            minWidth={minWidth}
            {...dataProps}
        >
            {children}
        </Box>
    );
};
