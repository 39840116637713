/**
 * Use this function to defer making translation keys in Crowdin until the end of
 * the development cycle of a feature.
 *
 * Example workflow:
 * 1) Add a new `TranslationDomain` - use a clear name describing the feature you're working on
 * 2) Start using `needsTranslations` to wrap user-facing text.
 * 3) Feature is eventually done
 * 4) Replace all usages of `needsTranslations` that uses your feature's scope with
 *    translation keys in Crowdin
 *
 * @param domain
 * A "key" identifying the domain / scope in which your text belongs.
 * It's purpose is to make it easy to identify and translate all texts with in the domain / scope.
 *
 * @param text
 * The text that'll be displayed to the user.
 */
export const needsTranslations = (domain: TranslationDomain, text: string) => {
    return text;
};

// eslint-disable-next-line no-restricted-syntax
export enum TranslationDomain {
    FinanceOnboardingFreeUs = 'finance-onboarding-free-us',
    GenericReportFilters = 'generic-report-filters',
    GetHelpWithFinances = 'get-help-with-finances',
    GetHelpWithFamlyPay = 'get-help-with-famly-pay',
    LearningFrameworkToggles = 'learning-framework-toggles',
    ReferenceMaterialToggles = 'reference-material-toggles',
    SettingConfigs = 'settings-config',
    BillPayer = 'bill-payer',
    HardDelete = 'hard-delete',
    FamlyStorePackagePricingPage = 'famly-store-package-pricing-page',
    ImmunizationOnboarding = 'immunization-onboarding-process',
    BillPayerAdoptionWidget = 'bill-payer-adoption-widget',
    AddNewSiteModal = 'add-new-site-modal',
    SignupTrialFlow = 'signup-trial-flow',
    FamlyHomeCategoryOnboarding = 'famly-home-category-onboarding',
    FinacePowerPage = 'finance-power-page',
    InternalPayments = 'internal-payments',
    TestChildActions = 'test-child-actions',
    FamlyStore = 'famly-store',
    FamlyPay = 'famly-pay',
    LandingPages = 'landing-pages',
    WatchMeGrow = 'watch-me-grow',
    SidekickTrial = 'sidekick-trial',
    MealBooking = 'meal-booking',
    AddonPurchase = 'addon-purchase',
    Village = 'famly-village',
    SidekickLearningBundle = 'sidekick-learning-bundle',
    SimplifiedUsBilling = 'simplified-us-billing',
}
