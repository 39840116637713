import React from 'react';
import {type BaseFieldProps, Field} from 'redux-form';
import styled, {css} from 'styled-components';

import FormInput from 'web-app/react/components/form-elements/containers/form-input';
import FormTextArea from 'web-app/react/components/form-elements/containers/form-text-area';
import {required as requiredValidator} from 'web-app/react/components/form-elements/validators';
import PlatformHelper from 'web-app/helpers/platform-helper';

export interface FormFieldProps {
    // Used in the styled component
    type?: any;
    ispassive?: string;
    isnostyle?: string;

    // This is needed until we can correctly type based on the component passed
    [x: string]: any;
}

export const StyledField = styled(Field)<BaseFieldProps<FormFieldProps> & FormFieldProps>`
    &[type='number'] {
        padding-top: 0;
        padding-bottom: 0;
    }

    ${props =>
        props.isnostyle
            ? ''
            : css`
                  margin-top: ${props.type === 'checkbox' ? '8px' : 0};
              `}

    ${props =>
        props.ispassive &&
        css`
            &&& {
                border-color: transparent;
                background: transparent;
                padding-left: 0;
                resize: none;
            }
        `}
`;

const appendRequiredValidator = (required?: boolean, validate?: any[]) => {
    if (required) {
        return validate ? [requiredValidator].concat(validate) : [requiredValidator];
    } else {
        return validate;
    }
};

const getComponent = (component: FormFieldProps['component'], type: FormFieldProps['type']) => {
    if (typeof component === 'string' && type !== 'checkbox') {
        if (component === 'input') {
            return FormInput;
        } else if (component === 'textarea') {
            return FormTextArea;
        }
    }
    return component;
};

export const FormField = ({
    passive,
    disabled,
    type,
    placeholder,
    required,
    validate,
    nostyle,
    component,
    ...restProps
}: FormFieldProps) => (
    <StyledField
        {...restProps}
        type={type}
        component={getComponent(component, type)}
        // This is done since currently iOS crashes if required form fields aren't filled out when trying to submit a form
        // Hopefully this can be removed in the future
        required={PlatformHelper.isIos() ? undefined : required}
        validate={PlatformHelper.isIos() ? appendRequiredValidator(required, validate) : validate}
        disabled={disabled || passive}
        placeholder={placeholder && passive ? '-' : placeholder}
        ispassive={passive ? 'passive' : ''}
        isnostyle={nostyle ? 'nostyle' : ''}
    />
);

export default FormField;
